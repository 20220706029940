.item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--TEXT-Base-Dark-Tertiary);
  
  .itemValue {
    color: var(--TEXT-Base-Light-Primary);
  }
}
