/* ---------------------
  - COLOR
  - Font Family
--------------------- */
/* =====================
  COLOR
===================== */
:root {
  /* Theme Color
  ------------------------*/
  --color-yellow: #E6FF3B;
  --color-pink: #FF0F7D;
  --color-gray: #030303;
  --color-black: #000000;

  /* Color for Parts
  ------------------------*/
  --color-border: #888888;
  --color-bg-black: #121212;
  --color-bg-gray: #292929;
  --color-overray: rgba(0, 0, 0, 0.8);

  /* Primitive Colors
  ------------------------*/
  /* BRAND */
  --BRAND-Dark80: #2E330C;
  --BRAND-Dark60: #485013;
  --BRAND-Dark40: #7D8A20;
  --BRAND-Dark20: #B1C52E;
  --BRAND-Base: #E6FF3B;
  --BRAND-Light20: #EBFF62;
  --BRAND-Light40: #F0FF89;
  --BRAND-Light60: #F5FFB1;
  --BRAND-Light80: #FAFFD8;

  /* GREEN */
  --GREEN-Dark80: #103427;
  --GREEN-Dark60: #285438;
  --GREEN-Dark40: #3F7348;
  --GREEN-Dark20: #579359;
  --GREEN-Base: #6EB269;
  --GREEN-Light20: #90C58A;
  --GREEN-Light40: #B2D7AC;
  --GREEN-Light60: #D4EACD;
  --GREEN-Light80: #F6FCEE;

  /* BLUE */
  --BLUE-Dark80: #153955;
  --BLUE-Dark60: #15517F;
  --BLUE-Dark40: #1569AA;
  --BLUE-Dark20: #1581D4;
  --BLUE-Base: #1599FE;
  --BLUE-Light20: #4AB0FE;
  --BLUE-Light40: #7FC7FF;
  --BLUE-Light60: #B3DEFF;
  --BLUE-Light80: #E8F5FF;

  /* RED */
  --RED-Dark80: #2D0B0B;
  --RED-Dark60: #892222;
  --RED-Dark40: #EE3B3B;
  --RED-Dark20: #F75353;
  --RED-Base: #FF6B6B;
  --RED-Light20: #FF8C8C;
  --RED-Light40: #FFAEAE;
  --RED-Light60: #FFCFCF;
  --RED-Light80: #FFF0F0;

  /* ORANGE */
  --ORANGE-Dark80: #2D1500;
  --ORANGE-Dark60: #443737;
  --ORANGE-Dark40: #95580C;
  --ORANGE-Dark20: #C87912;
  --ORANGE-Base: #FC9A18;
  --ORANGE-Light20: #FDB14C;
  --ORANGE-Light40: #FEC880;
  --ORANGE-Light60: #FEDEB4;
  --ORANGE-Light80: #FFF5E8;

  /* BLACK / WHITE */
  --BLACK-Black: #000000;
  --WHITE-White: #FFFFFF;

  /* GRAY */
  --GRAY-Dark95: #1B1B1B;
  --GRAY-Dark90: #222222;
  --GRAY-Dark85: #2E2E2E;
  --GRAY-Dark80: #393939;
  --GRAY-Dark60: #4C4C4C;
  --GRAY-Dark40: #5F5F5F;
  --GRAY-Dark20: #6C6C6C;
  --GRAY-Base: #8A8A8A;
  --GRAY-Light20: #ABABAB;
  --GRAY-Light40: #C5C5C5;
  --GRAY-Light60: #D6D6D6;
  --GRAY-Light80: #DEDEDE;
  --GRAY-Light90: #EBEBEB;
  --GRAY-Light95: #F3F3F3;

  /* Semantic Colors
  ------------------------*/
  /* TEXT/Base */
  --TEXT-Base-Dark-Primary: var(--BLACK-Black);
  --TEXT-Base-Dark-Secondary: var(--GRAY-Dark40);
  --TEXT-Base-Dark-Tertiary: var(--GRAY-Base);
  --TEXT-Base-Light-Primary: var(--WHITE-White);
  --TEXT-Base-Light-Secondary: var(--GRAY-Light60);
  --TEXT-Base-Light-Tertiary: var(--GRAY-Light40);
  --TEXT-Base-Colored-Primary: var(--BRAND-Base);
  --TEXT-Base-Colored-Secondary: var(--BLUE-Base);

  /* TEXT/Button/Filled */
  --TEXT-Button-Filled-Primary: var(--BLACK-Black);
  --TEXT-Button-Filled-Primary-Hover: var(--BLACK-Black);
  --TEXT-Button-Filled-Primary-Focus: var(--BLACK-Black);
  --TEXT-Button-Filled-Primary-Disabled: var(--BRAND-Dark60);
  --TEXT-Button-Filled-Secondary: var(--BLACK-Black);
  --TEXT-Button-Filled-Secondary-Hover: var(--BLACK-Black);
  --TEXT-Button-Filled-Secondary-Focus: var(--BLACK-Black);
  --TEXT-Button-Filled-Secondary-Disabled: var(--GRAY-Dark90);
  --TEXT-Button-Filled-Tertiary: var(--WHITE-White);
  --TEXT-Button-Filled-Tertiary-Hover: var(--WHITE-White);
  --TEXT-Button-Filled-Tertiary-Focus: var(--WHITE-White);
  --TEXT-Button-Filled-Tertiary-Disabled: var(--BLUE-Dark60);
  --TEXT-Button-Filled-Delete: ar(--WHITE-White);
  --TEXT-Button-Filled-Delete-Hover: var(--WHITE-White);
  --TEXT-Button-Filled-Delete-Focus: var(--WHITE-White);
  --TEXT-Button-Filled-Delete-Disabled: var(--ORANGE-Dark60);

  /* TEXT/Button/Outlined */
  --TEXT-Button-Outlined-Primary: var(--BRAND-Base);
  --TEXT-Button-Outlined-Primary-Hover: var(--BRAND-Dark20);
  --TEXT-Button-Outlined-Primary-Focus: var(--BRAND-Base);
  --TEXT-Button-Outlined-Primary-Disabled: var(--BRAND-Dark60);
  --TEXT-Button-Outlined-Secondary: var(--GRAY-Light40);
  --TEXT-Button-Outlined-Secondary-Hover: var(--GRAY-Light20);
  --TEXT-Button-Outlined-Secondary-Focus: var(--GRAY-Light40);
  --TEXT-Button-Outlined-Secondary-Disabled: var(--GRAY-Dark80);
  --TEXT-Button-Outlined-Tertiary: var(--BLUE-Base);
  --TEXT-Button-Outlined-Tertiary-Hover: var(--BLUE-Dark20);
  --TEXT-Button-Outlined-Tertiary-Focus: var(--BLUE-Base);
  --TEXT-Button-Outlined-Tertiary-Disabled: var(--BLUE-Dark60);
  --TEXT-Button-Outlined-Delete: var(--RED-Dark20);
  --TEXT-Button-Outlined-Delete-Hover: var(--RED-Dark40);
  --TEXT-Button-Outlined-Delete-Focus: var(--RED-Dark20);


  /* TEXT/Link */
  --TEXT-Link-Primary: var(--GRAY-Light40);
  --TEXT-Link-Primary-Hover: var(--GRAY-Light20);
  --TEXT-Link-Primary-Focus: var(--GRAY-Light40);
  --TEXT-Link-Primary-Disabled: var(--GRAY-Dark40);
  --TEXT-Link-Secondary: var(--BRAND-Base);
  --TEXT-Link-Secondary-Hover: var(--BRAND-Dark20);
  --TEXT-Link-Secondary-Focus: var(--BRAND-Base);
  --TEXT-Link-Secondary-Disabled: var(--BRAND-Dark60);

  /* TEXT/Status */
  --TEXT-Status-Success: var(--GREEN-Dark40);
  --TEXT-Status-Warning: var(--ORANGE-Base);
  --TEXT-Status-Error: var(--RED-Base);
  --TEXT-Status-Disabled: var(--GRAY-Dark20);

  /* TEXT/Tag */
  --TEXT-Tag-Light: var(--WHITE-White);
  --TEXT-Tag-Dark: var(--BLACK-Black);
  --TEXT-Tag-Request: var(--ORANGE-Base);
  --TEXT-Tag-Reserve: var(--GREEN-Dark40);
  --TEXT-Tag-Done: var(--GRAY-Dark20);

  /* TEXT/Tab */
  --TEXT-Tab-Primary: var(--BRAND-Base);
  --TEXT-Tab-Primary-Disabled: var(--GRAY-Light20);
  --TEXT-Tab-Secondary: var(--BLACK-Black);
  --TEXT-Tab-Secondary-Disabled: var(--WHITE-White);

  /* TEXT/Tooltip */
  --TEXT-Tooltip-Primary: var(--WHITE-White);

  /* TEXT/Form */
  --TEXT-Form-Placeholder: var(--GRAY-Base);
  --TEXT-Form-Input: var(--WHITE-White);
  --TEXT-Form-Input: var(--GRAY-Dark60);

  /* TEXT/Schedule */
  --TEXT-Schedule-Weekdays: var(--WHITE-White);
  --TEXT-Schedule-Holiday: var(--RED-Base);
  --TEXT-Schedule-Saturday: var(--BLUE-Light40);

  /* TEXT/Pagination */
  --TEXT-Pagination-Primary: var(--BRAND-Base);
  --TEXT-Pagination-Primary-Disabled: var(--GRAY-Light20);

  /* BG/Base */
  --BG-Base-Dark-Primary: var(--GRAY-Dark95);
  --BG-Base-Dark-Secondary: var(--GRAY-Dark90);
  --BG-Base-Dark-Tertiary: var(--GRAY-Dark85);
  --BG-Base-Dark-Quaternary: var(--GRAY-Dark80);
  --BG-Base-Dark-Basic: var(--BLACK-Black);
  --BG-Base-Light-Primary: var(--WHITE-White);
  --BG-Base-Light-Secondary: var(--GRAY-Light90);
  --BG-Base-Light-Tertiary: var(--GRAY-Light60);
  --BG-Base-Colored-Primary: var(--BRAND-Base);
  --BG-Base-Colored-Secondary: var(--BLUE-Base);

  /* BG/Button */
  --BG-Button-Primary: var(--BRAND-Base);
  --BG-Button-Primary-Hover: var(--BRAND-Dark20);
  --BG-Button-Primary-Focus: var(--BRAND-Base);
  --BG-Button-Primary-Disabled: var(--BRAND-Dark80);
  --BG-Button-Secondary: var(--GRAY-Light40);
  --BG-Button-Secondary-Hover: var(--GRAY-Light20);
  --BG-Button-Secondary-Focus: var(--GRAY-Light40);
  --BG-Button-Secondary-Disabled: var(--GRAY-Dark85);
  --BG-Button-Tertiary: var(--BLUE-Base);
  --BG-Button-Tertiary-Hover: var(--BLUE-Dark20);
  --BG-Button-Tertiary-Focus: var(--BLUE-Base);
  --BG-Button-Tertiary-Disabled: var(--BLUE-Dark80);
  --BG-Button-Delete: var(--RED-Dark20);
  --BG-Button-Delete-Hover: var(--RED-Dark40);
  --BG-Button-Delete-Focus: var(--RED-Dark20);
  --BG-Button-Delete-Disabled: var(--RED-Dark80);

  /* BG/Form */
  --BG-Form-Dark: var(--GRAY-Dark90);
  --BG-Form-Light: var(--GRAY-Dark40);
  --BG-Form-Dark-Disabled: var(--GRAY-Dark85);
  --BG-Form-Error: var(--RED-Dark80);

  /* BG/Status */
  --BG-Status-Success: var(--GREEN-Light80);
  --BG-Status-Warning: var(--ORANGE-Light80);
  --BG-Status-Error: var(--RED-Light80);
  --BG-Status-Info: var(--WHITE-White);
  --BG-Status-Disabled: var(--GRAY-Light90);

  /* BG/Tag */
  --BG-Tag-Light: var(--WHITE-White);
  --BG-Tag-Dark: var(--GRAY-Dark40);
  --BG-Tag-Request: var(--ORANGE-Light80);
  --BG-Tag-Reserve: var(--GREEN-Light60);
  --BG-Tag-Done: var(--GRAY-Light80);

  /* BG/Tag */
  --BG-Tab-Dark-Primary: var(--GRAY-Dark95);
  --BG-Tab-Dark-Secondary: var(--GRAY-Dark80);
  --BG-Tab-Dark-Tertiary: var(--GRAY-Dark60);
  --BG-Tab-Light-Primary: var(--WHITE-White);

  /* BG/RadioButton */
  --BG-RadioButton-Primary: var(--BRAND-Base);
  --BG-RadioButton-Secondary: var(--GRAY-Dark80);

  /* BG/Checkbox */
  --BG-Checkbox-Primary: var(--GRAY-Dark80);
  --BG-Checkbox-Secondary: var(--BLUE-Base);

  /* BG/Tooltip */
  --BG-Tooltip-Primary: var(--GRAY-Dark20);

  /* BG/Badge */
  --BG-Badge-Primary: var(--RED-Dark40);
  --BG-Badge-Secondary: var(--BRAND-Base);
  --BG-Badge-Tertiary: var(--BLUE-Base);

  /* BG/Switch */
  --BG-Switch-On: var(--BLUE-Base);
  --BG-Switch-On-Sub: var(--BLUE-Light60);
  --BG-Switch-Off: var(--GRAY-Base);

  /* BG/Rating */
  --BG-Rating-Rated: var(--ORANGE-Light20);
  --BG-Rating-NoRated: var(--GRAY-Base);

  /* BG/Pagination */
  --BG-Pagination-Primary: var(--GRAY-Dark95);
  --BG-Pagination-Primary-Hover: var(--GRAY-Dark90);
  --BG-Pagination-Primary-Focus: var(--GRAY-Dark85);
  --BG-Pagination-Primary-Disabled: var(--BLACK-Black);

  /* STROKE/Base */
  --STROKE-Base-Primary: var(--GRAY-Light90);
  --STROKE-Base-Secondary: var(--GRAY-Dark20);
  --STROKE-Base-Tertiary: var(--GRAY-Dark80);
  --STROKE-Base-Colored-Primary: var(--BRAND-Base);
  --STROKE-Base-Colored-Secondary: var(--BLUE-Base);

  /* STROKE/Button */
  --STROKE-Button-Primary: var(--BRAND-Base);
  --STROKE-Button-Primary-Hover: var(--BRAND-Dark20);
  --STROKE-Button-Primary-Focus: var(--BRAND-Dark40);
  --STROKE-Button-Primary-Disabled: var(--BRAND-Dark80);
  --STROKE-Button-Secondary: var(--GRAY-Light40);
  --STROKE-Button-Secondary-Hover: var(--GRAY-Light20);
  --STROKE-Button-Secondary-Focus: var(--GRAY-Dark20);
  --STROKE-Button-Secondary-Disabled: var(--GRAY-Dark80);
  --STROKE-Button-Tertiary: var(--BLUE-Base);
  --STROKE-Button-Tertiary-Hover: var(--BLUE-Dark20);
  --STROKE-Button-Tertiary-Focus: var(--BLUE-Dark40);
  --STROKE-Button-Tertiary-Disabled: var(--BLUE-Dark80);
  --STROKE-Button-Delete: var(--RED-Dark20);
  --STROKE-Button-Delete-Hover: var(--RED-Dark40);
  --STROKE-Button-Delete-Focus: var(--RED-Dark20);
  --STROKE-Button-Delete-Disabled: var(--RED-Dark80);

  /* STROKE/Form */
  --STROKE-Form-Normal: var(--GRAY-Dark60);
  --STROKE-Form-Normal-Hover: var(--GRAY-Dark60);
  --STROKE-Form-Normal-Focus: var(--BRAND-Base);
  --STROKE-Form-Error: var(--RED-Base);

  /* STROKE/Tab */
  --STROKE-Tab-Primary: var(--BRAND-Base);
  --STROKE-Tab-Secondary: var(--WHITE-White);
  --STROKE-Tab-Secondary-Disabled: var(--GRAY-Light40);

  /* STROKE/Tag */
  --STROKE-Tag-Primary: var(--GRAY-Dark60);
  --STROKE-Tag-Secondary: var(--GRAY-Dark20);

  /* STROKE/RadioButton */
  --STROKE-RadioButton-Primary: var(--GRAY-Light40);

  /* STROKE/Checkbox */
  --STROKE-Checkbox-Primary: var(--GRAY-Light40);

  /* STROKE/Selected */
  --STROKE-Selected-Primary: var(--BLUE-Base);
  --STROKE-Selected-Secondary: var(--BRAND-Base);

  /* STROKE/Schedule */
  --STROKE-Schedule-Primary: var(--GRAY-Dark80);
  --STROKE-Schedule-Secondary: var(--GRAY-Dark20);

  /* STROKE/Pagination */
  --STROKE-Pagination-Primary: var(--BRAND-Base);
  --STROKE-Pagination-Primary-Disabled: var(--GRAY-Base);

  /* ICON/Base */
  --ICON-Base-Primary: var(--WHITE-White);
  --ICON-Base-Secondary: var(--GRAY-Base);
  --ICON-Base-Tertiary: var(--BLACK-Black);

  /* ICON/Colored */
  --ICON-Colored-Primary: var(--BRAND-Base);
  --ICON-Colored-Secondary: var(--BLUE-Base);

  /* ICON/Button/Filled */
  --ICON-Button-Filled-Primary: var(--BLACK-Black);
  --ICON-Button-Filled-Primary-Hover: var(--BLACK-Black);
  --ICON-Button-Filled-Primary-Focus: var(--BLACK-Black);
  --ICON-Button-Filled-Primary-Disabled: var(--BRAND-Dark60);
  --ICON-Button-Filled-Secondary: var(--BLACK-Black);
  --ICON-Button-Filled-Secondary-Hover: var(--BLACK-Black);
  --ICON-Button-Filled-Secondary-Focus: var(--BLACK-Black);
  --ICON-Button-Filled-Secondary-Disabled: var(--GRAY-Dark90);
  --ICON-Button-Filled-Tertiary: var(--WHITE-White);
  --ICON-Button-Filled-Tertiary-Hover: var(--WHITE-White);
  --ICON-Button-Filled-Tertiary-Focus: var(--WHITE-White);
  --ICON-Button-Filled-Tertiary-Disabled: var(--BLUE-Dark60);

  /* ICON/Button/Outlined */
  --ICON-Button-Outlined-Primary: var(--BRAND-Base);
  --ICON-Button-Outlined-Primary-Hover: var(--BRAND-Dark20);
  --ICON-Button-Outlined-Primary-Focus: var(--BRAND-Base);
  --ICON-Button-Outlined-Primary-Disabled: var(--BRAND-Dark60);
  --ICON-Button-Outlined-Secondary: var(--GRAY-Light40);
  --ICON-Button-Outlined-Secondary-Hover: var(--GRAY-Light20);
  --ICON-Button-Outlined-Secondary-Focus: var(--GRAY-Light40);
  --ICON-Button-Outlined-Secondary-Disabled: var(--GRAY-Dark80);
  --ICON-Button-Outlined-Tertiary: var(--BLUE-Base);
  --ICON-Button-Outlined-Tertiary-Hover: var(--BLUE-Dark20);
  --ICON-Button-Outlined-Tertiary-Focus: var(--BLUE-Base);
  --ICON-Button-Outlined-Tertiary-Disabled: var(--BLUE-Dark60);

  /* ICON/Status */
  --ICON-Status-Success: var(--GREEN-Base);
  --ICON-Status-Warning: var(--ORANGE-Light20);
  --ICON-Status-Error: var(--RED-Base);
  --ICON-Status-Info: var(--GRAY-Base);

  /* Card */
  --BG-Card-Normal: var(--GRAY-Dark80);
  --BG-Card-Light: var(--GRAY-Dark60);

  /* Gradient */
  --Gradient: linear-gradient(to right, #DF18FF 0%, #FF0000 100%);
  --Gradient-Transparent: linear-gradient(119deg, rgba(223, 24, 255, 0.20) 13.41%, rgba(255, 0, 0, 0.20) 92.31%);
}

/* =====================
  Font Family
===================== */
:root {
  /* Base Font
  ------------------------*/
  --font-base: 'Roboto', sans-serif;
  --font-noto: 'Noto Sans JP', sans-serif;
}

/* =====================
  Z-Index
===================== */
:root {
  --z-index-dialog: 1000;
  --z-index-overlay-photo: 900;
}
